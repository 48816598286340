/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { RiPrinterLine, RiDownloadLine, RiArrowLeftLine, RiCheckLine, RiUserLine, RiTeamLine, RiBriefcaseLine, RiCalendarCheckLine, RiFileTextLine, RiTimeLine, RiListCheck, RiShieldCheckLine, RiArrowRightSLine } from "react-icons/ri"

const OnboardingChecklistTemplatePage = () => {
  
  // Function to handle printing the template
  const handlePrint = () => {
    if (typeof window !== 'undefined') {
      window.print();
    }
  }
  
  return (
    <Layout>
      <SEO 
        title="Onboarding Process Checklist | Comprehensive New Hire Roadmap"
        description="Download our structured onboarding process checklist to ensure a smooth transition for new employees. Includes pre-boarding, first day, first week, and first month milestones."
        keywords={[
          "onboarding process checklist",
          "new hire onboarding template",
          "employee onboarding plan",
          "onboarding timeline template",
          "structured onboarding process",
          "new employee orientation checklist",
          "HR onboarding workflow",
          "onboarding tasks template",
          "first week onboarding plan",
          "employee integration checklist"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Link 
              to="/guides/employee-onboarding" 
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontSize: '0.9rem',
                textDecoration: 'none',
                mb: 3,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <RiArrowLeftLine sx={{ mr: 1 }} /> Back to Employee Onboarding Guide
            </Link>
            
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Onboarding Process Checklist
            </h1>
            
            <p sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              A structured timeline of tasks for HR, managers, and new hires during the onboarding process.
              This comprehensive checklist ensures that no critical steps are missed when integrating a new team member.
            </p>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 4
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiPrinterLine /> Print Checklist
              </button>
              
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <RiDownloadLine /> Request Customizable Template
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main content will be added here */}
      
      {/* Main Content */}
      <div sx={{
        maxWidth: '1200px',
        mx: 'auto',
        px: 3,
        py: [4, 5],
        "@media print": {
          maxWidth: "100%",
          p: 0
        }
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto'
        }}>
          {/* Introduction */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Why Use an Onboarding Checklist?
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              A structured onboarding checklist ensures consistency, accountability, and clarity in your onboarding process. 
              Research shows that organizations with a standardized onboarding program experience 62% greater new hire 
              productivity and 50% better new hire retention. This comprehensive checklist helps HR teams, managers, 
              and new employees navigate the critical onboarding period with confidence.
            </p>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              {[
                {
                  title: "Ensure Consistency",
                  description: "Provide the same high-quality onboarding experience for every new hire",
                  icon: <RiListCheck />
                },
                {
                  title: "Increase Efficiency",
                  description: "Streamline the process and eliminate redundancies in onboarding workflows",
                  icon: <RiTimeLine />
                },
                {
                  title: "Improve Accountability",
                  description: "Clearly assign responsibilities to HR, managers, and new employees",
                  icon: <RiUserLine />
                },
                {
                  title: "Accelerate Integration",
                  description: "Help new hires reach productivity faster with a structured approach",
                  icon: <RiTeamLine />
                }
              ].map((benefit, index) => (
                <div key={`benefit-${index}`} sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <div sx={{ 
                    display: 'flex', 
                    alignItems: 'flex-start',
                    mb: 2
                  }}>
                    <span sx={{ 
                      color: 'primary', 
                      fontSize: '1.5rem',
                      mr: 2,
                      lineHeight: 1
                    }}>
                      {benefit.icon}
                    </span>
                    <h3 sx={{ 
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      m: 0,
                      lineHeight: 1.3
                    }}>
                      {benefit.title}
                    </h3>
                  </div>
                  <p sx={{ 
                    fontSize: '0.95rem',
                    m: 0,
                    lineHeight: 1.5 
                  }}>
                    {benefit.description}
                  </p>
                </div>
              ))}
            </div>
          </section>
          
          {/* Checklist sections will go here */}
          
          {/* Pre-Boarding Section */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Pre-Boarding Checklist (Before Day One)
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              The pre-boarding phase begins after offer acceptance and continues until the employee's first day. 
              This critical period sets the foundation for a successful onboarding experience by preparing both
              the organization and the new hire.
            </p>
            
            <div sx={{
              mb: 4,
              borderRadius: '8px',
              overflow: 'hidden',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)'
            }}>
              {/* HR Tasks */}
              <div>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiUserLine sx={{ mr: 2 }} /> HR Department Tasks
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Send offer letter and collect signed acceptance",
                      "Initiate background check process (if applicable)",
                      "Prepare and send new hire paperwork package",
                      "Create employee file and set up in HRIS",
                      "Schedule orientation sessions and first-week meetings",
                      "Prepare benefits enrollment information",
                      "Send welcome email with first day instructions",
                      "Create onboarding schedule for first week",
                      "Assign onboarding buddy or mentor",
                      "Prepare welcome kit"
                    ].map((task, index) => (
                      <li key={`hr-task-${index}`} sx={{ 
                        display: 'flex',
                        py: 2,
                        borderBottom: index !== 9 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <div sx={{ 
                          width: '24px',
                          height: '24px',
                          borderRadius: '4px',
                          border: '1px solid',
                          borderColor: 'muted',
                          mr: 3,
                          flexShrink: 0
                        }} />
                        <span sx={{ fontSize: '0.95rem' }}>{task}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              
              {/* Manager Tasks */}
              <div sx={{ borderTop: '1px solid', borderColor: 'muted' }}>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiBriefcaseLine sx={{ mr: 2 }} /> Hiring Manager Tasks
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Prepare 30-60-90 day onboarding plan",
                      "Schedule introductory meetings with key team members",
                      "Prepare initial projects and tasks",
                      "Set up job shadowing opportunities if applicable",
                      "Communicate new hire arrival to team",
                      "Prepare workstation and physical space",
                      "Review role responsibilities and adjust if needed",
                      "Identify training needs and resources"
                    ].map((task, index) => (
                      <li key={`mgr-task-${index}`} sx={{ 
                        display: 'flex',
                        py: 2,
                        borderBottom: index !== 7 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <div sx={{ 
                          width: '24px',
                          height: '24px',
                          borderRadius: '4px',
                          border: '1px solid',
                          borderColor: 'muted',
                          mr: 3,
                          flexShrink: 0
                        }} />
                        <span sx={{ fontSize: '0.95rem' }}>{task}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              
              {/* IT/Admin Tasks */}
              <div sx={{ borderTop: '1px solid', borderColor: 'muted' }}>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiShieldCheckLine sx={{ mr: 2 }} /> IT and Administrative Tasks
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Create email account and system logins",
                      "Set up workstation with required software",
                      "Prepare access badges/keys and security clearances",
                      "Configure access permissions to relevant systems",
                      "Order necessary equipment and verify delivery",
                      "Set up phone, voicemail, and communication tools",
                      "Provide IT support contact information",
                      "Add to relevant email lists and distribution groups"
                    ].map((task, index) => (
                      <li key={`it-task-${index}`} sx={{ 
                        display: 'flex',
                        py: 2,
                        borderBottom: index !== 7 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <div sx={{ 
                          width: '24px',
                          height: '24px',
                          borderRadius: '4px',
                          border: '1px solid',
                          borderColor: 'muted',
                          mr: 3,
                          flexShrink: 0
                        }} />
                        <span sx={{ fontSize: '0.95rem' }}>{task}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
          
          {/* First Day and Week sections will go here */}
          
          {/* First Day Section */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              First Day Checklist
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              The first day sets the tone for a new employee's experience. A well-planned first day helps reduce anxiety,
              builds excitement, and begins the integration process effectively. Focus on making the employee feel welcome
              and providing the essential information they need to navigate their new environment.
            </p>
            
            <div sx={{
              mb: 4,
              borderRadius: '8px',
              overflow: 'hidden',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)'
            }}>
              {/* HR Tasks */}
              <div>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiUserLine sx={{ mr: 2 }} /> HR Department Tasks
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Greet employee and provide warm welcome",
                      "Complete remaining new hire paperwork",
                      "Review company policies and procedures",
                      "Conduct benefits enrollment session",
                      "Provide company tour and facilities orientation",
                      "Introduce to key personnel and departments",
                      "Verify all systems access is functioning",
                      "Review security protocols and emergency procedures"
                    ].map((task, index) => (
                      <li key={`hr-first-day-${index}`} sx={{ 
                        display: 'flex',
                        py: 2,
                        borderBottom: index !== 7 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <div sx={{ 
                          width: '24px',
                          height: '24px',
                          borderRadius: '4px',
                          border: '1px solid',
                          borderColor: 'muted',
                          mr: 3,
                          flexShrink: 0
                        }} />
                        <span sx={{ fontSize: '0.95rem' }}>{task}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              
              {/* Manager Tasks */}
              <div sx={{ borderTop: '1px solid', borderColor: 'muted' }}>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiBriefcaseLine sx={{ mr: 2 }} /> Manager Tasks
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Welcome employee and introduce to team members",
                      "Provide overview of department/team structure",
                      "Review job description and responsibilities",
                      "Explain how performance will be measured",
                      "Discuss communication norms and team practices",
                      "Review first week schedule and expectations",
                      "Assign simple, achievable first tasks",
                      "Schedule end-of-day check-in meeting"
                    ].map((task, index) => (
                      <li key={`mgr-first-day-${index}`} sx={{ 
                        display: 'flex',
                        py: 2,
                        borderBottom: index !== 7 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <div sx={{ 
                          width: '24px',
                          height: '24px',
                          borderRadius: '4px',
                          border: '1px solid',
                          borderColor: 'muted',
                          mr: 3,
                          flexShrink: 0
                        }} />
                        <span sx={{ fontSize: '0.95rem' }}>{task}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              
              {/* New Employee Tasks */}
              <div sx={{ borderTop: '1px solid', borderColor: 'muted' }}>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiTeamLine sx={{ mr: 2 }} /> New Employee Tasks
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Complete all required paperwork and forms",
                      "Set up workstation and test access to systems",
                      "Review employee handbook",
                      "Join required communication channels",
                      "Set up payroll direct deposit",
                      "Take ID photo and receive access badge",
                      "Configure email signature and voicemail",
                      "Note questions for manager or HR"
                    ].map((task, index) => (
                      <li key={`employee-first-day-${index}`} sx={{ 
                        display: 'flex',
                        py: 2,
                        borderBottom: index !== 7 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <div sx={{ 
                          width: '24px',
                          height: '24px',
                          borderRadius: '4px',
                          border: '1px solid',
                          borderColor: 'muted',
                          mr: 3,
                          flexShrink: 0
                        }} />
                        <span sx={{ fontSize: '0.95rem' }}>{task}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
          
          {/* First Week section will go here */}
          
          {/* First Week & Month Section */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              First Week & Month Checklist
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              The first week and month are crucial for deeper integration and learning. During this period, 
              focus on role-specific training, building relationships, and establishing clear performance expectations.
              This time sets the foundation for long-term success and engagement.
            </p>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              {/* First Week */}
              <div sx={{
                borderRadius: '8px',
                overflow: 'hidden',
                boxShadow: '0 2px 15px rgba(0,0,0,0.06)'
              }}>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <RiCalendarCheckLine sx={{ mr: 2 }} /> First Week Tasks
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Complete required compliance training",
                      "Attend department/team meetings",
                      "Schedule one-on-ones with key stakeholders",
                      "Begin role-specific training",
                      "Review key processes and workflows",
                      "Set up recurring meetings",
                      "Complete any remaining setup tasks",
                      "Begin work on initial assignments",
                      "Check in with onboarding buddy regularly",
                      "Meet with manager for weekly recap"
                    ].map((task, index) => (
                      <li key={`first-week-${index}`} sx={{ 
                        display: 'flex',
                        py: 2,
                        borderBottom: index !== 9 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <div sx={{ 
                          width: '24px',
                          height: '24px',
                          borderRadius: '4px',
                          border: '1px solid',
                          borderColor: 'muted',
                          mr: 3,
                          flexShrink: 0
                        }} />
                        <span sx={{ fontSize: '0.95rem' }}>{task}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              
              {/* First Month */}
              <div sx={{
                borderRadius: '8px',
                overflow: 'hidden',
                boxShadow: '0 2px 15px rgba(0,0,0,0.06)'
              }}>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <RiCalendarCheckLine sx={{ mr: 2 }} /> First Month Tasks
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Complete all mandatory training",
                      "Finalize 30-60-90 day goals with manager",
                      "Participate in cross-functional meetings",
                      "Schedule additional training as needed",
                      "Begin regular responsibilities and projects",
                      "Provide/receive initial feedback on performance",
                      "Meet with HR for onboarding check-in",
                      "Attend any company social events",
                      "Identify growth opportunities",
                      "Review and clarify expectations"
                    ].map((task, index) => (
                      <li key={`first-month-${index}`} sx={{ 
                        display: 'flex',
                        py: 2,
                        borderBottom: index !== 9 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <div sx={{ 
                          width: '24px',
                          height: '24px',
                          borderRadius: '4px',
                          border: '1px solid',
                          borderColor: 'muted',
                          mr: 3,
                          flexShrink: 0
                        }} />
                        <span sx={{ fontSize: '0.95rem' }}>{task}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
          
          {/* Implementation Tips */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Implementation Tips
            </h2>
            
            <div sx={{
              bg: '#f8f9fa',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)'
            }}>
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                {[
                  "Customize this checklist for different roles and departments",
                  "Use a digital onboarding system to track task completion",
                  "Collect feedback from new hires to continuously improve",
                  "Consider cultural differences for global onboarding programs",
                  "Balance information delivery with relationship building",
                  "Ensure clear ownership for each onboarding task",
                  "Adapt the checklist for remote and hybrid employees",
                  "Create a centralized repository for all onboarding resources"
                ].map((tip, index) => (
                  <li key={`implementation-tip-${index}`} sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 3,
                    fontSize: '0.95rem',
                    lineHeight: 1.5
                  }}>
                    <span sx={{
                      color: 'primary',
                      mr: 3,
                      mt: '3px',
                      flexShrink: 0
                    }}>
                      <RiCheckLine />
                    </span>
                    {tip}
                  </li>
                ))}
              </ul>
            </div>
          </section>
          
          {/* Related Resources */}
          <section>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Related Resources
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)'],
              gap: 4
            }}>
              {[
                {
                  title: "New Employee Welcome Kit",
                  description: "Comprehensive package of orientation materials, company information, and first-day essentials.",
                  link: "/resources/templates/welcome-kit-template"
                },
                {
                  title: "Role-Specific Training Plan",
                  description: "Customizable template for creating structured learning paths for different roles.",
                  link: "/contact"
                },
                {
                  title: "Onboarding Experience Survey",
                  description: "Feedback form to evaluate and improve the onboarding process based on new hire experiences.",
                  link: "/contact"
                },
                {
                  title: "Employee Onboarding Guide",
                  description: "Comprehensive guide to effective employee onboarding best practices and strategies.",
                  link: "/guides/employee-onboarding"
                }
              ].map((resource, index) => (
                <Link 
                  key={`related-resource-${index}`}
                  to={resource.link}
                  sx={{
                    textDecoration: 'none',
                    color: 'text',
                    p: 3,
                    borderRadius: '8px',
                    boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    display: 'flex',
                    flexDirection: 'column',
                    bg: 'white',
                    '&:hover': {
                      transform: 'translateY(-3px)',
                      boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  <h3 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'primary'
                  }}>
                    {resource.title}
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    lineHeight: 1.5,
                    mb: 3,
                    flexGrow: 1
                  }}>
                    {resource.description}
                  </p>
                  
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    color: 'primary',
                    mt: 'auto'
                  }}>
                    {resource.link === "/contact" ? "Request Template" : "View Template"} <RiArrowRightSLine sx={{ ml: 1 }} />
                  </div>
                </Link>
              ))}
            </div>
          </section>
          
        </div>
      </div>
      
    </Layout>
  )
}

export default OnboardingChecklistTemplatePage 